export default {
  // The keys defined here will override those in the base locale file, for example:
  // 'theme_vendor': 'D-BAT'
  theme_vendor: 'Lil Kickers',
  'customers.TermsDialog.accept':
    'By clicking ‘Accept’ you verify that you have read and accepted the policy and terms of {customerName, select, theme_vendor {} other {{customerName} and}} Upper Hand Inc.',
  'client_theme.ThemeSidebar.subHeader': 'Welcome',
  'client_theme.ThemeSidebar.mission':
    'Step onto the field during any Lil’ Kickers class and you will find it transformed into a world created just for your child. Our well trained coaches will thrill your child with 50 minutes of highly creative, high energy and age-appropriate activities that serve as a great introduction to soccer, and help children master developmental milestones, appropriate for their age.',
  'client_theme.ThemeSidebar.learn_more': 'Learn More',
};
