export default {
  name: 'Lil Kickers',
  siteURL: 'https://www.lilkickers.com/lil-kickers-programs',
  logo: {
    url: 'https://upperhand-app.s3.amazonaws.com/static/images/lil_kickers.png',
    width: 325,
  },
  login: {
    desktopSignInColor: '#006ab3',
    mobileSignInColor: '#006ab3',
  },
  palette: {
    // TODO: remove primary1Color and accent1Color replaace int usages with a new one
    primary1Color: '#006ab3',
    accent1Color: '#e31837',

    primary: {
      main: '#006ab3',
    },
    secondary: {
      main: '#e31837',
    },
  },
  sideNav: {
    selectedColor: '#006ab3',
  },
  icons: {
    cart: '#006ab3',
  },
};
